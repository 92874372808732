<template>
    <div class="Submit">
        <el-dialog :title="$t('huo-dong-bao-ming')" center :visible.sync="show" width="600px" append-to-body>
            <el-form
                label-width="195px"
                size="small"
                style="max-width: 600px;"
                :model="form"
                ref="form"
                :rules="rules"
                hide-required-asterisk
                :disabled="!!activityId"
            >
                <el-form-item :label="$t('huo-dong-ming-cheng')">
                    <span class="from-val">{{ info.title }}</span>
                </el-form-item>
                <el-form-item :label="$t('huo-dong-shi-jian')">
                    <span class="from-val">{{ info.date }}</span>
                </el-form-item>
                <el-form-item :label="$t('huo-dong-di-dian')">
                    <span class="from-val">{{ info.address }}</span>
                </el-form-item>
                <el-form-item :label="$t('huo-dong-chang-ci')" prop="session" v-if="info.session">
                    <div>
                        <el-radio
                            v-for="(item, index) in info.session"
                            :key="index"
                            v-model="form.session"
                            :label="item"
                            border
                            >{{ item }}</el-radio
                        >
                    </div>
                </el-form-item>
                <el-divider></el-divider>
                <el-form-item :label="$t('xing-ming')" prop="nickname">
                    <el-input
                        v-model="form.nickname"
                        :placeholder="$t('can-yu-zhe-xing-ming')"
                        class="input1"
                    ></el-input>
                </el-form-item>

                <el-form-item :label="$t('cheng-hu')" prop="sex">
                    <el-radio-group v-model="form.sex">
                        <el-radio :key="index" :label="item[0]" v-for="(item, index) in sexModels">{{
                            item[1].label
                        }}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item
                    :label="$t('gong-si-ji-gou-yuan-xiao-ming-cheng')"
                    prop="companyName"
                    :rules="rulesRequire"
                >
                    <el-input v-model="form.companyName" :placeholder="$t('qing-shu-ru')" class="input1"></el-input>
                </el-form-item>
                <el-form-item :label="$t('zhi-wei')" prop="position">
                    <el-input v-model="form.position" :placeholder="$t('qing-shu-ru')" class="input1"></el-input>
                </el-form-item>
                <el-form-item :label="$t('dian-hua')" prop="phone" :rules="rulesRequire">
                    <el-input v-model="form.phone" :placeholder="$t('qing-shu-ru')" class="input1"></el-input>
                </el-form-item>
                <el-form-item :label="$t('you-xiang')" prop="email">
                    <el-input v-model="form.email" :placeholder="$t('qing-shu-ru')" class="input1"></el-input>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer" v-if="!activityId">
                <el-button size="medium" type="warning" @click="onSubmit" v-loading="loading">{{
                    $t('que-ding')
                }}</el-button>
                <el-button size="medium" @click="show = false">{{ $t('qu-xiao') }}</el-button>
            </span>

            <span slot="footer" class="dialog-footer" v-else>
                <el-button
                    size="medium"
                    type="warning"
                    @click="show = false"
                    v-loading="loading"
                    style="min-width: 200px;"
                    >{{ $t('fan-hui') }}</el-button
                >

                <el-button class="cancel" type="info" plain size="medium" @click="cancel">
                    {{ $t('qu-xiao-bao-ming') }}
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import formPage from '../../mixins/formPage';
import { getOne } from '../../utils/Array';
export default {
    name: 'submit',
    mixins: [formPage],
    data() {
        return {
            show: false,
            form: {},
            registInfo: {}
        };
    },
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        userActivity: {
            type: Boolean,
            default: false
        },
        activityId: {
            type: Number,
            default: 0
        }
    },
    watch: {
        info() {
            if (this.info.id) {
                this.$http
                    .post(
                        '/eventRegistration/all',
                        {
                            query: {
                                userId: this.$store.state.userInfo.id,
                                informationId: this.activityId,
                                del: false
                            }
                        },
                        { body: 'json' }
                    )
                    .then(res => {
                        if (!res.empty) {
                            this.$emit('update:activityId', res.content[0].id);
                            this.registInfo = res.content[0];
                        } else {
                            this.$emit('update:activityId', 0);
                            this.registInfo = {};
                        }
                    });
            }
        }
    },
    methods: {
        showForm() {
            this.checkLogin()
                .then(userInfo => {
                    if (this.activityId) {
                        this.form = {
                            ...this.registInfo
                        };
                    } else {
                        this.form = {
                            userId: userInfo.id,
                            sex: userInfo.sex,
                            position: userInfo.position,
                            nickname: userInfo.nickname,
                            phone: userInfo.phone,
                            email: userInfo.email,
                            companyName: userInfo.chCompanyName,
                            informationId: this.info.id,
                            session: getOne(this.info.session)
                        };
                    }

                    this.show = true;
                    console.log(this.form);
                })
                .catch(e => {
                    if (e === '未登录') {
                        this.login();
                    } else if (e && e.error) {
                        this.$message.warning(e.error);
                    }
                });
        },
        submit() {
            this.$http
                .post(
                    '/eventRegistration/save',
                    {
                        ...this.form
                    },
                    { body: 'json' }
                )
                .then(res => {
                    this.$message.success(this.$t('bao-ming-cheng-gong'));
                    this.show = false;
                    this.$emit('update:activityId', res.id);
                })
                .catch(e => {
                    console.log(e);
                    this.$message.warning(e.error);
                });
        },
        cancel() {
            this.$confirm(this.$t('que-ding-yao-qu-xiao-bao-ming-ma'), this.$t('ti-shi'), {
                confirmButtonText: this.$t('li-ji-qu-xiao'),
                cancelButtonText: this.$t('zai-xiang-xiang'),
                type: 'warning'
            })
                .then(() => {
                    return this.$http.post('/eventRegistration/del/' + this.activityId, '', '', true);
                })
                .then(res => {
                    this.$message.success(this.$t('qu-xiao-bao-ming-cheng-gong'));
                    this.show = false;
                    this.$emit('update:activityId', 0);
                    this.$emit('cancelSucess');
                })
                .catch(e => {
                    if (e.error) {
                        console.log(e);
                        this.$message.warning(e.error);
                    }
                });
        }
    }
};
</script>
<style lang="scss" scoped>
.from-val {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #292c33;
}
</style>

<style lang="scss">
.el-radio.is-bordered {
    .el-radio__input {
        display: none;
    }

    .el-radio__label {
        font-size: 12px;
        padding-left: 0;
    }

    margin-right: 0px;
}
.el-radio.is-bordered.is-checked {
    .el-radio__label {
        color: #ffa526;
    }
}

.el-button.cancel {
    background-color: transparent;
    border-width: 0px;
}
</style>
