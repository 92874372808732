<template>
    <router-link class="news-info" replace :to="'/NewsDetail?id=' + this.info.id">
        <div class="news-content">
            <el-image :src="info.img" fit="cover"></el-image>
            <div class="text">
                <div>{{ info.title }}</div>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'news',
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.news-content {
    position: relative;
    .el-image {
        width: 280px;
        height: 200px;
    }

    .text {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        padding: 14px 12px 10px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 1) 100%);
        div {
            font-size: 14px;
            color: #ffffff;
            line-height: 26px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
    }
}
</style>