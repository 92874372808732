<template>
    <el-container class="NewsDetail" direction="vertical">
        <el-main v-loading="loading">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">{{ $t('shou-ye') }}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/NEWS' }">{{ $t('xin-wen-zi-xun') }}</el-breadcrumb-item>
                <el-breadcrumb-item v-if="showType" :to="typeTo" replace>{{
                    $i18n.t(typeInfo.name)
                }}</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $t('xiang-qing') }}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="default-info" v-if="information.type !== 'INDUSTRY_ACTIVITIES'">
                <div class="main-title">
                    {{ information.title }}
                </div>
                <div class="sub">
                    {{ information.introduction }}
                </div>

                <div class="default-tips">
                    <span>{{ $t('fa-bu-ri-qi') }}：{{ information.createdAt }}</span>
                    <span
                        >{{ $t('fa-bu-zhe') }}：{{
                            getName(information, ['vendorChName', 'vendorEnName']) || $t('ping-tai-wen-zhang')
                        }}</span
                    >
                </div>
            </div>

            <div class="info" v-else>
                <div class="img">
                    <auto-img :src="information.img" mul="70%"></auto-img>
                </div>

                <div class="info-main">
                    <div class="title">{{ information.title }}</div>

                    <div class="sub" v-if="information.type !== 'INDUSTRY_ACTIVITIES'">
                        {{ information.introduction }}
                    </div>

                    <template v-else>
                        <div class="info-item" style="margin-top: 24px;">
                            <div class="text1">{{ $t('shi-jian') }}：</div>
                            <div class="text2">{{ information.date }}</div>
                        </div>
                        <div class="info-item">
                            <div class="text1">{{ $t('di-zhi') }}：</div>
                            <div class="text2">{{ information.address }}</div>
                        </div>
                        <div class="info-item">
                            <div class="text1">{{ $t('chang-ci') }}：</div>
                            <div class="text2">
                                <el-tag
                                    size="mini"
                                    effect="plain"
                                    type="info"
                                    v-for="(item, index) in information.session"
                                    :key="index"
                                    >{{ item }}</el-tag
                                >
                            </div>
                        </div>

                        <el-button :type="isEnd ? 'info' : 'warning'" :disabled="isEnd" class="btn" @click="takeForm">{{
                            isEnd
                                ? $t('bao-ming-jie-shu')
                                : activityId
                                ? $t('cha-kan-bao-ming-xin-xi')
                                : $t('li-ji-bao-ming')
                        }}</el-button>
                    </template>
                </div>
            </div>

            <div class="detail" v-html="content"></div>

            <Submit ref="submit" :activityId.sync="activityId" :info="information" />
        </el-main>

        <div class="about" v-if="relatedList.length > 0">
            <div class="about-title">{{ $t('xiang-guan-xin-wen') }}</div>
            <swiper class="information-swiper" :options="informationOption">
                <swiper-slide v-for="item in relatedList" :key="item.id">
                    <NewsGride :info="item"></NewsGride>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination" v-siwperPagination></div>
            </swiper>
        </div>
    </el-container>
</template>
<script>
import { mapState } from 'vuex';
import { InformationType } from '../../utils/AppState';
import NewsGride from '../../components/news/GrideNews';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

import { saveStatistics } from '../../utils/CommonApi';

import Submit from '../../components/activity/Submit';
export default {
    name: 'NewsDetail',
    metaInfo() {
        return {
            title: this.pageName || this.$t('imt-ke-ji-ping-tai')
        };
    },
    data() {
        return {
            loading: false,
            information: {},
            from: '',
            activityId: 0,
            informationId: 0,
            pageName: '',
            relatedList: [],
            informationOption: {
                slidesPerView: 'auto',
                spaceBetween: 30,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    // type: 'progressbar',
                    bulletClass: 'progress-bullet',
                    bulletActiveClass: 'progress-bullet-active'
                }
            }
        };
    },
    computed: {
        showType() {
            return !!(this.$route.query.type || '');
        },
        typeInfo() {
            if (this.information.type) {
                return [...InformationType].find(item => {
                    return item[0].includes(this.information.type);
                })[1];
            }
            return {
                name: ''
            };
        },
        typeTo() {
            if (this.from.name === 'NEWS') {
                return {
                    path: this.from.path,
                    query: this.from.query
                };
            } else {
                return {
                    path: '/NEWS',
                    query: { type: this.information.type }
                };
            }
        },
        isEnd() {
            return this.information.isEnd || false;
        },
        content() {
            var detail = this.information.content || '';
            detail = detail.replace(/<img/g, '<img  oncontextmenu="return false;" ');
            detail = detail.replace(
                /<video/g,
                '<video oncontextmenu="return false;" onmouseleave="leaveVideo(this)"  controlsList="nodownload noremote footbar" '
            );
            return detail;
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.from = from;
            return;
        });
    },
    mounted() {
        window.addEventListener('beforeunload', this.beforeunloadEvent);
        this.getDetail();
    },
    watch: {
        $route() {
            window.scrollTo({
                top: document.getElementById('banner').offsetHeight - 90
            });
            this.getDetail();
        }
    },
    methods: {
        takeForm() {
            this.$refs.submit.showForm();
        },
        getDetail() {
            this.loading = true;
            this.$http
                .get('/information/get/' + this.$route.query.id)
                .then(res => {
                    this.loading = false;
                    this.information = res;
                    // if (this.$route.query.type !== res.type) {
                    //     this.$router.replace({
                    //         name: this.$route.name,
                    //         query: {
                    //             id: this.$route.query.id,
                    //             type: res.type
                    //         }
                    //     });
                    // }
                    this.informationId = res.id;
                    saveStatistics(this.informationId, 'INFORMATION');
                    this.pageName = res.title;
                })
                .catch(e => {
                    this.information = {};
                });

            this.$http
                .get('/information/related', {
                    id: this.$route.query.id
                })
                .then(res => {
                    this.relatedList = res;
                })
                .catch(e => {
                    this.relatedList = [];
                });
        }
    },
    components: {
        Submit,
        NewsGride,
        Swiper,
        SwiperSlide
    },
    beforeRouteLeave(from, to, next) {
        saveStatistics(this.informationId, 'INFORMATION', true);
        next();
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
.el-container {
    margin-left: 30px;
}
.el-main {
    padding: 30px 50px;
    min-height: 50vh;
    background: #ffffff;
    position: relative;
    z-index: 1;
}
.default-info {
    padding: 30px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #f2f3f5;

    .main-title {
        font-size: 24px;
        font-weight: bold;
        color: #000000;
        line-height: 33px;
    }

    .sub {
        font-size: 12px;
        color: #878d99;
        line-height: 22px;
        margin-top: 16px;
    }

    .default-tips {
        font-size: 12px;
        color: #565b66;
        line-height: 17px;
        margin-top: 24px;
        span + span {
            margin-left: 90px;
        }
    }
}
.info {
    display: flex;
    padding: 30px 0;

    .img {
        width: 390px;
        flex-shrink: 0;

        .autoImg {
            cursor: inherit;
        }
    }

    .info-main {
        padding: 10px 0 10px 24px;
        flex-grow: 1;

        .title {
            font-size: 24px;
            color: #000000;
            line-height: 33px;
            font-weight: bold;
        }

        .sub {
            font-size: 14px;
            color: #565b66;
            line-height: 24px;
            margin-top: 14px;
        }
    }
}

.info-item {
    display: flex;
    align-items: center;
    margin-top: 12px;

    .text1 {
        font-size: 12px;
        color: #bcc1cc;
        line-height: 22px;
    }

    .text2 {
        font-size: 12px;
        color: #565b66;
        line-height: 22px;
        margin-left: 10px;

        .el-tag {
            margin-right: 10px;
            border-radius: 0px;
        }
    }
}

.btn {
    margin-top: 40px;
    min-width: 250px;
}

.detail {
    font-size: 14px;
    // font-family: PingFangSC-Medium, PingFang SC;
    // font-weight: 500;
    color: #000000;
    line-height: 22px;
}

.about {
    padding: 0 20px 0px;
    background: #ffffff;
    margin-top: 18px;

    .about-title {
        font-size: 16px;
        font-weight: bold;
        color: #292c33;
        line-height: 22px;
        padding: 17px 30px;
        border-bottom: 1px solid #f2f3f5;
    }
}
.information-swiper {
    padding-top: 16px;
    padding-bottom: 16px;
    .swiper-slide {
        width: 280px;
    }

    .swiper-pagination {
        top: 300px;
    }
}
</style>
<style lang="scss">
.detail {
    p {
        margin-block-start: 0;
        margin-block-end: 0;
    }
}
</style>
